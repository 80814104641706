
import Vuetify from "vuetify"
import Vue from "vue"

export default Vue.extend({
  name: "About",
  data() {
    return {
      vuetifyVersion: Vuetify.version,
      vueVersion: Vue.version,
      utilisateur: this.$store.state.utilisateur
    }
  }
})
